* {
    font-family: 'Cutive Mono', monospace !important;
    text-transform: lowercase !important;
    font-size: 16px; 
    background-color: white;
}

body{
    background: white;
    color:#333333;
}

.page-container{
    padding:2vw;
    padding-top:4vw;
}

.system-button{
    --background: #57f76b00;
    color: black;
    border: 1px black solid;
    margin-right:10px;
    box-shadow: 3px 3px 0px 0px black;
}

.inline{
    display:inline;
}

.block{
    display:block;
}

.token-button{
    width:fit-content;
    margin-top:10px;
}

.user-address{
    --background:white;
    --color: black;
}

.page-name{
    color:black;
    font-size: 16px;
}
.page-name.mobile{
    max-width: 100%;
}

.page-artist{
    font-size: 16px;
    white-space: normal;
}

.page-header{ 
    background:white;
}

.page-header::after{
    display:none;
}

.icon-margin-left{
    margin-left: 2px;
}

/*/8/8/8/8/8/8/8/8/8/8/8/8/8*/
/* Permaweb Popover Styles  */
/*/8/8/8/8/8/8/8/8/8/8/8/8/8*/


.permaweb-info{
    --background: white;
}

.popup-info > ion-backdrop {
    --backdrop-opacity: 0.75;
  }

.popup-info .popover-content {
    top: 50px !important;
    left: 30% !important;
    border-radius: 10px;
    padding: 5px;
    width: 42%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .popup-info .popover-content.mobile {
    left: 5% !important;
    width: 90%;
  }

.popup-info .popup-info-content.desktop{
    height: 475px;
    overflow: scroll;
    --background: transparent;
  }

.popup-info .popover-info-content.mobile {
    height: unset !important;
    padding-bottom:20px !important;
}


.popup-info .eco-info-content.desktop{
    height: 300px !important;
    overflow: scroll;
    --background: transparent;
  }

  .popup-info .eco-info-content.mobile {
    height: unset !important;
    padding-bottom:20px !important;
}

.popup-info .eco-info-list.mobile{
    width:90%;
    margin-left:5%;
    background: transparent;
}

  .popup-info .popup-info-list.mobile{
    width:90%;
    margin-left:5%;
    background: transparent;
}

.popup-info .popup-info-list{
    width:82%;
    margin-left:9%;
    margin-bottom:15px;
    background: transparent;
}


.popup-info .popup-info-label{
    margin-top: 2px;
    margin-bottom: 2px;
    color:black
}

.popup-info .popup-info-header-text{
    font-size: x-large;
    font-weight: 500;
}

.popup-info .popup-info-header{
    margin-left: 9% !important;
    margin-top: 3% !important;
    margin: 10px;
    width: 82%;
    top: 0;
    font-size: 22px;
    line-height: 2;
}

.eco-info-header{
    margin-left: 9% !important;
    margin-top: 3% !important;
    margin: 10px;
    width: 82%;
    top: 0;
    font-size: 22px;
    color:black !important;
    line-height: 2;
}

/* .popup-info .popup-info-header ion-icon{
    vertical-align: sub;
} */

.popup-info .popup-info-header h2,
.popup-info .popup-info-header ion-icon
{
    color:black;
}

.close-popup-button{
    position: absolute;
    right: -45px;
    top: -15px;
    width:18px;
    height:18px;
    cursor: pointer;
}

.close-popup-button.mobile{
    position: absolute;
    right: -20px;
    top: -20px;
}

/*/8/8/8/8/8/8/8/8/8/8/8/8/8*/
/* claim-popover Styles  */
/*/8/8/8/8/8/8/8/8/8/8/8/8/8*/

.claim-popover{
    --background: white;
}

.claim-popover > ion-backdrop {
    --backdrop-opacity: 0.75;
  }

.claim-popover .popover-content {
    top: 50px !important;
    left: 30% !important;
    border-radius: 10px;
    padding: 15px;
    width: 42%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow-x:hidden;
    overflow-y:scroll;
  }

  .claim-popover .popover-content.mobile {
    top: 20px !important;
    left: 4% !important;
    padding: 16px;
    width: 90%;
  }


.claim-popover .claim-popover-content{
    height: 350px !important;
    --background: transparent;
  }

  .claim-popover .claim-popover-content.mobile{
    height: unset !important;
    padding-bottom:20px !important;
    --background: transparent;
  }

.claim-popover .claim-popover-list{
    width:82%;
    margin-left:9%;
    background: transparent;
}

.popup-info .claim-popover-list.mobile{
    width:90%;
    margin-left:5%;
    background: transparent;
}

.claim-popover .claim-popover-label{
    margin-top: 2px;
    margin-bottom: 2px;
    color:black
}

.claim-popover .claim-popover-header-text{
    font-size: x-large;
    font-weight: 500;
}
.claim-popover .claim-popover-header-text.mobile{
    font-size: x-large;
    font-weight: 500;
}

.claim-popover .claim-popover-header{
    margin-left: 9% !important;
    margin: 10px;
    width: 82%;
    top: 0;
}

.claim-popover .claim-popover-header h2,
.claim-popover .claim-popover-header ion-icon
{
    color:black;
    margin-top:10px;
}

.reveal{
    /* width: 16px;
    vertical-align: bottom; */
}

.i-fs{
    text-decoration: none;
}

.reveal a{
    pointer-events: none;
    text-decoration: none;
}

.reveal-image{
    width: 100px;
    display: none;
    margin-top: 20px;
}

.token-iframe{
    border:0;
    width:700px !important;
    height:700px !important;
}

.token-image{
    border:0;
    width:100% !important;
    height:auto !important;
}

.token-audio{
    height: 20px !important;
}

audio::-webkit-media-controls-enclosure {
    max-width: 500px;
}

.display-container{
    margin:auto;
    margin-top:2%;
    width:fit-content;
    position:relative;
}

.display-container-asset{
    width:700px;
    height:auto;
    max-height:700px;
    z-index:100;
    max-width: 100%;
    position: relative;
}

.display-column{
    text-align: center;
    position: relative;
}

.display-column-left{
    text-align: left;
}

.mute-button{
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 20px;
    right: 10px;
    z-index: 10000;
    color:rgba(255, 255, 255, 0.7);
    cursor:pointer;
    background: transparent;
    -webkit-filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0));
    filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0));
}


.toolbar-title{
    white-space:unset !important;
}